$(document).ready(function() {
  (function($, sr) {
    // debouncing function from John Hann
    // http://unscriptable.com/index.php/2009/03/20/debouncing-javascript-methods/
    let debounce = function(func, threshold, execAsap) {
      let timeout;

      return function debounced() {
        let obj = this,
          args = arguments;

        function delayed() {
          if (!execAsap) {
            func.apply(obj, args);
          }
          timeout = null;
        }

        if (timeout) {
          clearTimeout(timeout);
        } else if (execAsap) {
          func.apply(obj, args);
        }

        timeout = setTimeout(delayed, threshold || 100);
      };
    };
    // smartresize
    jQuery.fn[sr] = function(fn) {
      return fn ? this.bind('resize', debounce(fn)) : this.trigger(sr);
    };
  })(jQuery, 'smartresize');

  function initShowHideEls() {
    /**
     * check if any show/hides exist on page
     */
    let $showHideEls = $('.fil-showhide');
    let showHideElsLen = $showHideEls.length;
    /**
     * loop through and format showhides if any exist on page
     */
    if (showHideElsLen > 0) {
      let cloneElName = '.clone-el';
      /**
       * remove any left-overs from previous calculations
       */
      $(cloneElName).remove();
      (function() {
        let i = showHideElsLen - 1;
        for (; i >= 0; i--) {
          let thisShowHide = $showHideEls.eq(i);
          let thisShowHideWidth = thisShowHide.width();
          let hiddenSection = thisShowHide.find('.showhide__hidden');
          /**
           * check show/hide state in-case open during resize event
           */
          let showHideOpen = thisShowHide.hasClass('is-open') ? true : false;
          /**
           * clone content
           */
          let thisShowHideContent = thisShowHide.html();
          /**
           * clone hidden element and append to body
           * measure cloned hidden element as open
           * apply open value as custom data attribute to original hidden element
           * use as part of open/close operation to ensure smooth and correct animation
           */
          let clonedTemplate = `<div class="clone-el" style="position:fixed;height:5000px;left:-${thisShowHideWidth *
            2}px;width:${thisShowHideWidth}px;">${thisShowHideContent}</div>`;
          $('body').append(clonedTemplate);
          let cloneElHiddenSection = $(`${cloneElName} .showhide__hidden`);
          /**
           * reset any height styles applied to get full open height
           */
          cloneElHiddenSection.css({
            'transition-duration': '0',
            height: 'auto',
            overflow: 'visible'
          });

          window.setTimeout(function() {
            return (function() {
              let storeHeight = cloneElHiddenSection.height();
              hiddenSection.attr('data-height', storeHeight);
              if (showHideOpen) {
                hiddenSection.css('height', `${storeHeight}px`);
              }
              $(cloneElName).remove();
            })();
          }, 0);
        }
      })();
    }
  }

  initShowHideEls();
  /**
   * rerun show/hide formatting on window resize
   */
  $(window).smartresize(function() {
    initShowHideEls();
  });

  $('.fil-showhide').resize(function() {
    console.log('el resizing: ', $(this).height());
  });

  $('.fil-showhide .accordion').on({
    'up.zf.accordion': function() {
      this.parentElement.scrollIntoView();
    }
  });

  /**
   * rerun show/hide formatting on window resize
   */
  $('.js-showhide .showhide-trigger').click(function() {
    let thisParent = $(this).closest('.fil-showhide');
    let openHeight = thisParent.find('.showhide__hidden').data('height');

    if (thisParent.hasClass('is-open')) {
      thisParent
        .removeClass('is-open')
        .addClass('is-closed')
        .find('.showhide__hidden')
        .css('height', '0');
    } else {
      thisParent
        .removeClass('is-closed')
        .addClass('is-open')
        .find('.showhide__hidden')
        .css('height', `${openHeight}px`);
    }
  });
});
