import Cookies from 'js-cookie';

const SESSION_VALIDATION_CHECKER_TIME = 300;
let isUserLoggedIn = Cookies.get('LoggedInUserStatus', { domain: '.fidelity.co.uk' });

let isUserLoggedInStatus;

let getLoggedInUserStatus = function() {
  const url = '/gateway/session/v1/continue?target=ei';
  const method = 'GET';

  $.ajax({
    url: url,
    method: method,
    dataType: 'json',
    cache: false,
    async: false,
    success: function(response) {
      console.log(response.message);
      isUserLoggedInStatus = response.message === 'success';
    },
    error: function() {
      console.log('Unable to authenticate User');
      isUserLoggedInStatus = false;
    }
  });
  console.log(`*USER LOGGED IN STATUS MESSAGE: ${isUserLoggedInStatus}`);
  actionPostLoggedInStatus(isUserLoggedInStatus);
};

function actionPostLoggedInStatus(isUserLoggedInStatus) {
  if (isUserLoggedInStatus) {
    setTimeout(getLoggedInUserStatus, SESSION_VALIDATION_CHECKER_TIME * 1000);
  } else {
    Cookies.set('LoggedInUserStatus', false, { domain: '.fidelity.co.uk' });
    window.location.reload();
  }
}

let heartBeatLoginStatusChecker = setTimeout(
  getLoggedInUserStatus,
  SESSION_VALIDATION_CHECKER_TIME * 1000
);

/* Call a timer to check the login status every 5 minutes only if the cookie value is true */

function callTimerFunc() {
  if (isUserLoggedIn === 'true' && isUserLoggedIn !== undefined) {
    // setTimeout(getLoggedInUserStatus, SESSION_VALIDATION_CHECKER_TIME * 1000);
    setTimeout(getLoggedInUserStatus, SESSION_VALIDATION_CHECKER_TIME * 1000);
  } else {
    clearTimeout(heartBeatLoginStatusChecker);
    return false;
  }
}

callTimerFunc();

$(window)
  .on('blur', function() {
    clearTimeout(heartBeatLoginStatusChecker);
  })
  .on('focus', callTimerFunc);
