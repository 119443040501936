// Shows and hides full author list in collated attribution component

const handleAttributionToggleClick = ($dropdown, openClass) => {
  return e => {
    if (!$dropdown.hasClass(openClass)) {
      $dropdown.addClass(openClass);
      e.stopPropagation();
    }
  };
};

export const initialiseClickHandlers = () => {
  const $dropdown = $('.fil-article-attribution-collated__dropdown');
  const dropdownOpenClass = 'fil-article-attribution-collated__dropdown--open';

  $('.fil-article-attribution-author__dropdown-toggle').on('click', handleAttributionToggleClick($dropdown, dropdownOpenClass));
  $('body').on('click', () => $dropdown.removeClass(dropdownOpenClass));
};