/*global Cookies */

$(window).on('load', function() {
  //  Progressing Profilling
  let marketoSyncForm = function() {
    // Update this to point to the api file
    this.apiFile = '/marketo/sync-api/';
    this.init = function() {
      this.getCookie();
      this.getToken();
    };

    this.getToken = function() {
      let self = this;
      // Query api to get temp token
      $.get(`${this.apiFile}gettoken`, function(res) {
        // Save token to instance of function
        const tokenObj = res;
        self.token = tokenObj.access_token;
        // After token has been fetched, fetch user object
        self.getUserObject();
      });
    };

    this.getCookie = function() {
      let cookie = Cookies.get('_mkto_trk', { domain: window.location.hostname }) || '';
      return cookie.split('token:')[1];
    };

    this.getUserObject = function() {
      let self = this;
      // Query api to fetch user object
      $.get(`${this.apiFile}getuser/?cookie=${this.getCookie()}`, function(res) {
        // If successful, attempt to populate the form
        if (res.success) {
          self.populateForm(res.result[0]);
        }
      });
    };

    this.populateForm = function(res) {
      const results = res;
      let inputName;
      if (typeof results === 'object') {
        // Populate input based on user api object
        Object.keys(results).forEach(function(key) {
          switch (key) {
            case 'dateOfBirth':
              inputName = 'DateOfBirth';
              break;
            case 'firstName':
              inputName = 'FirstName';
              break;
            case 'lastName':
              inputName = 'LastName';
              break;
            case 'email':
              inputName = 'Email';
              break;
            case 'phone':
              inputName = 'Phone';
              break;
            case 'postalCode':
              inputName = 'PostalCode';
              break;
            case 'TITL':
              inputName = 'CivilTitle';
              break;
            default:
              inputName = results[key];
              break;
          }

          // Find form input, populate with object value
          $(`.enquiryFormWrapper input[name="${inputName}"]`).val(results[key]);
          $(`.enquiryFormWrapper input[name="${inputName}"]`).triggerHandler('input');
        });
      }
    };

    this.init();
  };

  //  Trigger Progressing Profilling On Marketo Form
  if ($('.enquiryFormWrapper').length) {
    new marketoSyncForm();
  }

  // AntiInjection

  const blockedCharacters = /[<>?\\/@&!#()*%]/g;

  if (window.MktoForms2) {
    window.MktoForms2.whenReady(function() {
      // Put your code that uses the form object here
      $('.enquiryFormWrapper input[type="text"]').each(function(index, e) {
        let inputName = jQuery(e)
          .attr('name')
          .replace(/ /g, '')
          .toLowerCase();

        if (inputName.indexOf('email') == -1) {
          // Check key on keydown
          $(e).on('keydown', function(e) {
            let value = e.key;
            if (blockedCharacters.exec(value) !== null) {
              /* Due to UI alignment issue, following code is being commented based on Marketo's feedback and will be taken care by marketo error handling mechanisum in future */
              //sendFormValidationMessage(inputField);
              return false;
            }
          });

          // Prevent copy and pasting banned chars
          $(e).on('input', function(e) {
            let value = e.target.value;
            value = value.replace(blockedCharacters, '');
            e.target.value = value;
          });
        }
      });

      // function sendFormValidationMessage(inputField) {
      //   const form = jQuery(inputField).closest('form');

      //   if (form.find('.error-message').length === 0) {
      //     form.prepend(
      //       "<strong><p class='error-message' style='color: red; position: absolute; bottom: 60px; font-family: sans-serif;'>The following characters are banned in text fields: < > ?  / @ & !</p></strong>"
      //     );
      //   }
      // }
    });
  }
});
