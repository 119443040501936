/* global countryCode, countryType, Cookies */

import URL from 'core-js/web/url';

$(document).ready(function() {
  if (countryType === 'outlk') {
    return;
  }

  checkCountryCode();

  /**
   * @description - Global nav logout onClick function
   */
  $('.logout').on('click touch', function(event) {
    event.preventDefault();

    if (countryCode === 'jp') {
      doLogoutAction_jp();
    } else {
      doLogoutAction();
    }
  });
});

const checkCountryCode = function() {
  if (typeof countryCode !== 'undefined') {
    switch (countryCode) {
      case 'uk':
        doLoggedInCheckUk();
        setupUkLogout();
        break;
      case 'de':
        doLoggedInCheckGermany();
        break;
      case 'jp':
        doLoggedInCheckJapan();
        break;
      default:
        break;
    }
  }
};

const userStateDfd = $.Deferred();
const getLoginState = function() {
  return userStateDfd.promise();
};

/**
 * @name - _processRequest
 * @description - Private function to process request
 */
let _processRequest = function(url, successHandler, isPost) {
  const method = isPost ? 'POST' : 'GET';

  if ('withCredentials' in new XMLHttpRequest()) {
    /* supports cross-domain requests */
    $.ajax({
      url: url,
      method: method,
      success: successHandler,
      dataType: 'json',
      xhrFields: { withCredentials: true },
      cache: false,
      error: () => {
        userStateDfd.resolve(false);
      }
    });
  }
};

/**
 * @name - loginDetector
 * @description - check whether the user is signed-in.
 */
let loginDetector = function(data) {
  switch (countryCode) {
    // For Germany and Japan, the validation APIs will only return a successful
    // response when the user is logged in. Therefore, to reach this code via
    // the AJAX success handler, we know we are logged in.
    case 'de':
    case 'jp':
      return true;
    case 'uk':
      return data.message === 'success';
    default:
      return data.login === 'true';
  }
};

/**
 * @name - loginHandler
 * @description - Handles show hide login or logout in the way finding bar
 */
let loginHandler = function(data) {
  if (loginDetector(data)) {
    userStateDfd.resolve(true);
    $('.wfb-nav').addClass('logged-in');
    $('#body-container').addClass('user-logged-in');
    $('.fil-header-jp .nav-bar .main-menu .tier-one-mypage').css('display', 'block');

    //if user signed in, 'news-list' will show specified secure page
    //otherwise it will show flatsite page
    //this logic is for FSKK only
    $('.fil-header-jp .megamenu .is_news_list_link').attr('href', function() {
      return $(this).data('secure-link');
    });
  } else {
    userStateDfd.resolve(false);
  }
};

const doLoggedInCheckUk = function() {
  const loginCheckURL = new URL('/gateway/session/v1/continue', window.location);
  loginCheckURL.searchParams.set('target', 'ei');
  loginCheckURL.searchParams.set('_', Date.now());
  _processRequest(loginCheckURL.href, loginHandler);
};

/**
 * @name - doLoggedInCheckGermany
 * @description - Does ajax call to secure site for login status and handles the login link in the header
 */
function doLoggedInCheckGermany() {
  if (window.loginCheckURL) {
    const url = `${window.loginCheckURL}?_=${new Date().getTime()}`;
    _processRequest(url, loginHandler);
  }
}

/**
 * @name - doLoggedInCheckJapan
 * @description - Does ajax call to secure site for login status and handles the login link in the header
 */
const doLoggedInCheckJapan = function() {
  if (window.loginCheckURL) {
    let loginCheckURL = `${window.loginCheckURL}?_=${new Date().getTime()}`;
    _processRequest(loginCheckURL, loginHandler);
  }
};

/**
 * @name - logoutHandler
 * @description - Handles logout success
 */
let logoutHandler = function() {
  $('.wfb-nav').removeClass('logged-in');
  $('#body-container').removeClass('user-logged-in');
};

/**
 * @name - doLogout
 * @description - Does ajax call to logout from secure site
 */
const doLogoutAction = function(needPost, _logoutHandler) {
  _logoutHandler = _logoutHandler || logoutHandler;

  if (window.logoutServiceURL) {
    let logoutServiceURL = `${window.logoutServiceURL}?_=${new Date().getTime()}`;
    _processRequest(logoutServiceURL, _logoutHandler, needPost);
  }
};

const doLogoutAction_jp = function() {
  doLogoutAction(true, function() {
    logoutHandler();
    $('.fil-header-jp .nav-bar .main-menu .tier-one-mypage').hide();

    let logoutLink = $('.logout[href]').attr('href');
    if (logoutLink) {
      top.location.href = logoutLink;
    } else {
      top.location.href = '/';
    }
  });
};

const setupUkLogout = function() {
  let logoutLink = $('.logout').attr('href');
  $('.logout').bind('click', function() {
    /*
      This change is to get the global FMR logout when the user clicks on the Logout from
      the logout button.
      The constant defines the URL which needs to be hit when the logout button is clicked
      based on whether it was a FMR based login or a normal FIL login
    */

    const { apiURL, methodType } = Cookies.get('SSOSESSION')
      ? { apiURL: '/gateway/affwebservices/public/saml2slo', methodType: 'GET' }
      : { apiURL: '/gateway/user/logout', methodType: 'POST' };

    $.ajax({
      type: methodType,
      url: apiURL,
      success: function() {
        if (sessionStorage) {
          if (sessionStorage.getItem('dGlk') != '') {
            window.sessionStorage.removeItem('dGlk');
          }
          if (sessionStorage.getItem('accountHoldingsPortfolio') != '') {
            window.sessionStorage.removeItem('accountHoldingsPortfolio');
          }
        }
        let domain = window.location.origin;
        let pingUrl = '';
        let targetUrl = '';
        if (domain.includes('sit.fidelity.co.uk') || domain.includes('uat.fidelity.co.uk')) {
          pingUrl = 'https://id-test.fidelity.co.uk';
        } else if (domain.includes('ebf.fidelity.co.uk') || domain.includes('per.fidelity.co.uk')) {
          pingUrl = 'https://id-stage.fidelity.co.uk';
        } else {
          pingUrl = 'https://id.fidelity.co.uk';
        }
        targetUrl = `${pingUrl}/idp/startSLO.ping?TargetResource=`;
        if (logoutLink) {
          //check for absolute url
          if (logoutLink.indexOf('http://') === 0 || logoutLink.indexOf('https://') === 0) {
            targetUrl = targetUrl + logoutLink;
          } else {
            targetUrl = targetUrl + domain + logoutLink;
          }
        } else {
          targetUrl = `${targetUrl + domain}/`;
        }
        window.location.href = targetUrl;
      }
    });
    return false;
  });
};

module.exports = {
  getLoginState,
  setupUkLogout
};
