const Paginator = require("_fil/fil-global-frontend/paginator");

document.addEventListener('DOMContentLoaded', () => {
    if ($("#OCF-wrapper").length > 0) {
        class Historical {
            constructor() {
                this.allFunds = [];
                this.filterFunds = [];
                this.queryString = "";
                this.resultWrapper = $('.search-results__wrapper');
                this.typeaHeadEnabled = $.fn.typeahead !== undefined;
                $('#OCF-search').attr('disabled', 'disabled');
                this.searchValueTriggerLength = 1; //character length to enable the search button
                this.init();
                this.bindEvents();
            }

            init(){
                $(".historical-charges-main").addClass("init-min-height");
                $("#body-container").removeClass("intl");
                let _this = this;
                $.ajax({
                    url: "/historical-charges/getfundlist/",
                    type: 'GET',
                    data: {
                    },
                    success: function (data) {
                        _this.allFunds = data;
                        const ISIN = $.trim(_this.getUrlParameter('ISIN'));
                        if(ISIN){
                            let ISINResult = _this.queryFunds('', ISIN);
                            if(ISINResult.ISIN){
                                const code = ISINResult['ISIN'];
                                const name = ISINResult['fundName'][0];
                                const id = ISINResult['id']
                                _this.showDetail(code, name, id);
                            }
                        }
                    }
                });
            }

            getUrlParameter(name) {
                name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
                let regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
                let results = regex.exec(window.location.search);
                return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
            }

            queryFunds(keyword='' ,ISIN='') {
                let fundObj = {};
                let fundNameArray = new Array();
                let _this = this;
                this.allFunds.forEach(function (item, index) {
                    let fundName = $.trim(item.Localization[0].fundName[0].shareclassLongName);
                    let isinCode = $.trim(item.isin);
                    let currentNameValueReplace = _this.replaceAll(fundName.toUpperCase(), '・', '');
                    let keywordValueReplace = $.trim(_this.replaceAll(keyword, '・', ''));
                    let isFoundName = false;
                    if(keywordValueReplace != ''){
                        isFoundName = currentNameValueReplace.toUpperCase().indexOf(keywordValueReplace.toUpperCase()) != -1;
                    }
                    let isISINCode = isinCode.toUpperCase() == $.trim(ISIN).toUpperCase() && isinCode != "";
                    if (isFoundName || isISINCode) {
                        fundNameArray.push(fundName);
                        fundObj['fundName'] = fundNameArray;
                        fundObj['ISIN'] = isinCode;
                        fundObj['id'] = item.id
                    }
                });
                return fundObj;
            }

            getSearchValue() {
                let value = $("#OCF-keyword").val();
                return $.trim(value);
            }

            bindEvents() {
                let _this = this;
                if (this.typeaHeadEnabled) {
                    //Type ahead call used on search bar and results
                    $('#OCF-keyword').typeahead(
                        {
                            hint: true,
                            highlight: true,
                            minLength: 1
                        },
                        {
                            limit: 999,
                            async: true,
                            source: function (query, processSync, processAsync) {
                                setTimeout(function () {
                                    let fundNameArray = _this.queryFunds(query)['fundName'];
                                    return processAsync(fundNameArray);
                                }, 0)
                            }
                        }
                    );
                }

                $('.typeahead').on('keyup', function (e) {
                    const value = _this.getSearchValue();
                    $('#fs_r').val((new Date()).getTime());
                    if (e.keyCode == 13 && value.length >= _this.searchValueTriggerLength) {
                        $('.typeahead').typeahead('close');
                        $('#OCF-keyword').blur();
                        _this.searchBtnClick();
                    }
                });

                document.getElementById("OCF-keyword").addEventListener("input",(v)=>{
                    _this.setSearchBtnStatus(_this.getSearchValue());
                })

                document.getElementById("OCF-keyword").addEventListener("propertychange",(v)=>{
                    _this.setSearchBtnStatus(_this.getSearchValue());
                })

                $('#OCF-search').on('click', function () {
                    const value = _this.getSearchValue();
                    if (value.length >= _this.searchValueTriggerLength) {
                        _this.searchBtnClick();
                    }
                });

                $('.tt-menu .tt-dataset').on('click', '.tt-suggestion', function () {
                    const queryText = $(this).text();
                    const ISINCode = _this.getISINCode(queryText)['isin'];
                    const id = _this.getISINCode(queryText)['id'];
                    _this.showDetail(ISINCode, queryText, id);
                });
            }

            setSearchBtnStatus(value){
                if (value.length >= this.searchValueTriggerLength) {
                    $('#OCF-search')
                        .removeAttr('disabled');
                } else {
                    $('#OCF-search')
                        .attr('disabled', 'disabled');
                }
            }

            searchBtnClick() {
                const keyword = this.getSearchValue();
                const fundItem = this.getFundItem(keyword);
                $('.OCF-pagination-wrapper').removeClass('hide');
                this.showList(fundItem);
            }

            getAllFunds() {
                return this.allFunds;
            }

            getFilterFunds() {
                return this.filterFunds;
            }

            getFundItem(queryText) {
                let _this = this;
                _this.filterFunds = [];
                this.allFunds.forEach(function (item, index) {
                    let fundName = item.Localization['0'].fundName['0'].shareclassLongName;
                    if (fundName.toUpperCase().indexOf(queryText.toUpperCase()) > -1) {
                        _this.filterFunds.push(item);
                    }
                });
                return _this.filterFunds;
            }

            getISINCode(name) {
                let code = {};
                this.allFunds.forEach(function (item, index) {
                    let fundName = item.Localization[0].fundName[0].shareclassLongName;
                    if (fundName == name) {
                        code['isin'] = item.isin;
                        code['id'] = item.id;
                        return;
                    }
                });
                return code;
            }

            getFundName(ISINCode) {
                let name;
                this.allFunds.forEach(function (item, index) {
                    let ISIN = item.isin;
                    if (ISIN == ISINCode) {
                        name = item.Localization[0].fundName[0].shareclassLongName;
                        return;
                    }
                });
                return name;
            }

            replaceAll(value, rep1, rep2) {
                if (value == null || value == '') {
                    return '';
                } else {
                    return value.replace(new RegExp(rep1, 'gm'), rep2);
                }
            }

            /**
             * Displays the loading spinner
             */
            addSpinner() {
                $('#search-results__spinner-container').show();
            }

            /**
             * Removes the loading spinner
             */
            removeSpinner() {
                $('#search-results__spinner-container').hide();
            }

            showDetail(code, name, id) {
                let _this = this;
                $('.resultsCount').addClass("hide");
                $('.OCF-pagination-wrapper').addClass('hide');
                $('.result-container').removeClass('hide');
                this.resultWrapper.empty();
                this.addSpinner();
                $.ajax({
                    url:`/historical-charges/getfunddetail/`,
                    type: 'GET',
                    dataType: "json",
                    data: {
                        'account':'UCITS',
                        'idType':'GSCID',
                        'ids':id,
                        'rollingDuration':5
                    },
                    success: function (data) {
                        window.info = data;
                        _this.removeSpinner();
                        $('.OCF-disclaimer').removeClass('hide');
                        _this.renderResult(data, $.trim(code), $.trim(name));
                    }
                });
            }

            renderResult(data, code, name) {
                $(".historical-charges-main").removeClass("init-min-height");
                let _this = this
                if(data.historicFees.length > 0){
                    $("#accordion").removeClass("hide");
                    let info = data.historicFees[0].details;
                    let thStr = `<tr><th class="category" width="50%"></th>`;
                    let ocfStr, maxEntryChargeStr, maxExitChargeStr, performanceFeeStr;
                    let yearArr = [];
                    let ocfArr = [];
                    let maxEntryChargeArr = [];
                    let maxExitChargeArr = [];
                    let performanceFeeArr = [];
                    if (info.length > 0) {
                        info.forEach((element, index) => {
                            const ocf = element.ocf;
                            const maxEntryCharge = element.maxEntryCharge;
                            const maxExitCharge = element.maxExitCharge;
                            const performanceFee = element.performanceFee;
                            yearArr.push(element.effectiveDate.split('/')[0]);
                            ocfArr.push(ocf);
                            maxEntryChargeArr.push(maxEntryCharge);
                            maxExitChargeArr.push(maxExitCharge);
                            performanceFeeArr.push(performanceFee);
                        });
                    }

                    if (yearArr.length > 0) {
                        yearArr = yearArr.reverse();
                        yearArr.forEach(year => {
                            thStr = thStr + `<th class="yearResult">${year}</th>`;
                        })
                        thStr = thStr + "</tr>";
                    }

                    if (ocfArr.length > 0) {
                        ocfArr = ocfArr.reverse();
                        ocfStr = `<tr id="ocf"><td class="category bold">Ongoing Charges</td>`;
                        $(".ocf-wrapper").removeClass("hide");
                        $("#ocfContent").empty();
                        ocfArr.forEach((v, i) => {
                            let result = _this.valueResult(v);
                            ocfStr = ocfStr + `<td class="bold text-right">${result}</td>`;
                            $("#ocfContent").append(`<div class="grid-x item-year">
                                                    <div class="cell small-6 text-left detail-year">${yearArr[i]}</div>
                                                    <div class="cell small-6 text-right detail-value">${result}</div>
                                                </div>`);
                        })
                        ocfStr = ocfStr + "</tr>";
                    } else {
                        $(".ocf-wrapper").addClass("hide");
                    }

                    if (maxEntryChargeArr.length > 0) {
                        maxEntryChargeArr = maxEntryChargeArr.reverse();
                        maxEntryChargeStr = `<tr id="maxEntryCharge"><td class="category bold">Entry Charge</td>`;
                        $(".entryCharges-wrapper").removeClass("hide");
                        $("#entryChargesContent").empty();
                        maxEntryChargeArr.forEach((v, i) => {
                            let result = _this.valueResult(v);
                            maxEntryChargeStr = maxEntryChargeStr + `<td class="bold text-right">${result}</td>`;
                            $("#entryChargesContent").append(`<div class="grid-x item-year">
                                                    <div class="cell small-6 text-left detail-year">${yearArr[i]}</div>
                                                    <div class="cell small-6 text-right detail-value">${result}</div>
                                                </div>`);
                        })
                        maxEntryChargeStr = maxEntryChargeStr + "</tr>";
                    } else {
                        $(".entryCharges-wrapper").addClass("hide");
                    }

                    if (maxExitChargeArr.length > 0) {
                        maxExitChargeArr = maxExitChargeArr.reverse();
                        maxExitChargeStr = `<tr id="maxExitCharge"><td class="category bold">Exit Charge</td>`;
                        $(".exitCharges-wrapper").removeClass("hide");
                        $("#exitChargesContent").empty();
                        maxExitChargeArr.forEach((v, i) => {
                            let result = _this.valueResult(v);
                            maxExitChargeStr = maxExitChargeStr + `<td class="bold text-right">${result}</td>`;
                            $("#exitChargesContent").append(`<div class="grid-x item-year">
                                                    <div class="cell small-6 text-left detail-year">${yearArr[i]}</div>
                                                    <div class="cell small-6 text-right detail-value">${result}</div>
                                                </div>`);
                        })
                        maxExitChargeStr = maxExitChargeStr + "</tr>";
                    } else {
                        $(".exitCharges-wrapper").addClass("hide");
                    }

                    if (performanceFeeArr.length > 0) {
                        performanceFeeArr = performanceFeeArr.reverse();
                        performanceFeeStr = `<tr id="performanceFee"><td class="category bold">Performance Fee</td>`;
                        $(".performanceCharges-wrapper").removeClass("hide");
                        $("#performanceChargesContent").empty();
                        performanceFeeArr.forEach((v, i) => {
                            let result = _this.valueResult(v);
                            performanceFeeStr = performanceFeeStr + `<td class="bold text-right">${_this.valueResult(v)}</td>`;
                            $("#performanceChargesContent").append(`<div class="grid-x item-year">
                                                    <div class="cell small-6 text-left detail-year">${yearArr[i]}</div>
                                                    <div class="cell small-6 text-right detail-value">${result}</div>
                                                </div>`);
                        })
                        performanceFeeStr = performanceFeeStr + "</tr>";
                    } else {
                        $(".performanceCharges-wrapper").addClass("hide");
                    }

                    let fundInfo = $(`<div class="selected-info__wrapper">
                                    <h3 class="selected-title">${name}</h3>
                                    <h4 class="selected-code">ISIN: ${code}</h4>
                                </div>`);
                    let resultTBL = $(`<table class="standard hide-for-small-only resultTBL"  data-paginator-results><tbody></tbody></table>`);
                    resultTBL.append($(thStr)).append(ocfStr).append($(maxEntryChargeStr)).append($(maxExitChargeStr)).append($(performanceFeeStr));
                    this.resultWrapper.append(fundInfo).append(resultTBL);
                }else{
                    $("#accordion").addClass("hide");
                    $(".OCF-disclaimer").addClass("hide");
                    this.resultWrapper.append(`<p class="text-center">There is insufficient data to provide historic charges for this fund.</p>`);
                }
            }

            valueResult(v) {
                let returnVal = Number($.trim(v));
                if(returnVal != 0){
                    returnVal = Number($.trim(v)) || "N/A";
                    return returnVal == "N/A" ? "N/A" : returnVal.toFixed(2) + "%";
                }else{
                    return '0%';
                }
            }

            showList(results) {
                const fundListRootEl = $('.result-container');
                $('.result-container').removeClass('hide');
                $('.OCF-disclaimer').addClass('hide');
                $(".resultsCount").removeClass("hide");
                if(!this.fundListTable){
                    this.fundListTable = new HistoricalPaginator(fundListRootEl);
                }
                this.fundListTable.rebuildTable(results);
            }
        }

        let historical = new Historical();

        class HistoricalPaginator extends Paginator {
            constructor(rootEl, opts = {}) {
                super(rootEl, opts);
                this.funds = "";
                this.$rootEl = $(rootEl);
                this.bindEvents();
            }

            bindEvents() {
                $('.result-container .search-results__wrapper').on('click', '.search-result__link', function () {
                    const code = $.trim($(this).closest('.search-result__content').find('.search-result__excerpt').text());
                    const name = $.trim($(this).text());
                    const id = historical.getISINCode(name)['id'];
                    $('.typeahead').typeahead('val', name);
                    historical.showDetail(code, name, id);
                });
            }

            rebuildTable(obj) {
                this.funds = obj;
                this.updatePagniation(obj);
                super.rebuild(obj);
            }

            updatePagniation(obj) {
                this.funds = obj || [];
                this.initPagination();
            }

            initPagination() {
                this.page = 0;
                this.perPage = 10;
            }

            buildResults(results) {
                historical.resultWrapper.empty();
                $("#accordion").addClass("hide");
                results.forEach(result => {
                    this.buildResult(result);
                });
            }

            buildResult(result) {
                let fundItem = $('<div class="search-result search-result--ukfunds">');
                let iconDiv = $(`<div class="search-result__icon-wrapper">
                            <img class="search-result__icon search-result__icon--fund" src="/static_cms/global_cms_uk_historical_charges/svg/iconsearchinvest.svg">
                            </div>`);
                let itemContentDiv = $('<div class="search-result__content">');
                let itemTitle = $(`<h3 class="search-result__title"><a class="search-result__link" href="javascript:void(0)">
                                ${result.Localization[0].fundName[0].shareclassLongName}
                                </a></h3>`);
                let ISINDiv = $(`<div class="search-result__excerpt"><span>${result.isin}</span></div>`);
                itemContentDiv.append(itemTitle).append(ISINDiv);
                fundItem.append(iconDiv).append(itemContentDiv);
                historical.resultWrapper.append(fundItem);
            }

            getTotalResults() {
                return this.funds.length;
            }

            getResultsForPage(page) {
                const curPage = page;
                const perPage = this.perPage;
                let curPageFundData = this.funds.slice(curPage * perPage, curPage * perPage + perPage);
                return curPageFundData;
            }
        }
    }
})

