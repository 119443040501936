const handleShareButtonClick = function ($dropdownSection, openClass, $dropdown) {
  return function (e) {
    if (!$dropdownSection.hasClass(openClass)) {
      $dropdownSection.addClass(openClass);
      moveDropdownLeftToAvoidCutoff($dropdown);
      e.stopPropagation();
    }
  };
};

const handlePageClick = function ($dropdownSection, openClass) {
  return function () {
    $dropdownSection.removeClass(openClass);
  };
};

const moveDropdownLeftToAvoidCutoff = function ($dropdown) {
  const dropdownWidth = 250;
  $dropdown.css('left', 0);
  const dropdownLeft = $dropdown.offset().left;
  const screenWidth = $(document).width();
  const offScreenRight = dropdownLeft + dropdownWidth - screenWidth;
  if (offScreenRight > 0) {
    $dropdown.css('left', `${-offScreenRight}px`);
  }
};

const initialiseClickHandlers = function () {
  /**
   * @description - Share links functionality
   */
  const $dropdownSection = $('.fil-share-links');
  const $dropdown = $dropdownSection.find('.fil-share-links__dropdown');
  const dropdownOpenClass = 'fil-share-links--open';

  $('.fil-share-links__share-button').on('click', handleShareButtonClick($dropdownSection, dropdownOpenClass, $dropdown));
  $('body').on('click', handlePageClick($dropdownSection, dropdownOpenClass));
};


$(document).ready(initialiseClickHandlers);

module.exports = {
  initialiseClickHandlers
};
