import { insertSectionsList } from './article_section_list';
import { formatBlockQuotes } from './blockquote_processor';
import { initialiseClickHandlers } from './attribution_toggle';
import { initialiseScrollHandler } from './article_social_links';

$(document).ready(() => {
  formatBlockQuotes();
  initialiseClickHandlers();
  initialiseScrollHandler();
  insertSectionsList();
});
