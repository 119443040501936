/* global Foundation */

$(document).ready(function() {
  if (!$('#isaSavingTool').length) {
    return;
  }

  // Housekeeping
  let canvasContainerWidth = document.getElementById('stageParticleWrap').offsetWidth;
  let canvas = document.getElementById('stageParticle');
  let ctx = canvas.getContext('2d');
  let particleCount = 12;

  let imageScale = (function() {
    let scale = 1 - (100 - canvasContainerWidth / particleCount) / 100;
    if (scale >= 1) {
      return 1;
    } else {
      return scale;
    }
  })();
  canvas.width = canvasContainerWidth;
  canvas.height = 100 * imageScale;

  let animDuration = 60;
  let animStages = {
    stage1: {
      active: false,
      duration: animDuration,
      target: canvas.width / particleCount
    },
    stage2: {
      active: false,
      duration: animDuration,
      target: (canvas.width / particleCount) * (particleCount - 1)
    },
    stage3: {
      active: false,
      duration: animDuration,
      target: 0
    }
  };

  let currentAnim = {
    startPosition: 0,
    endPosition: 0,
    animationFrame: animDuration
  };

  let particles = [];

  function setup() {
    makeParticles(particleCount);
    setOriginPositions();
    draw();
    new Foundation.Tabs($('#isa-saving-tool-tabs'));
  }

  function draw() {
    if (currentAnim.animationFrame < animDuration) {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      updateParticles();
    }

    window.requestAnimationFrame(draw);
  }

  function makeParticles(count) {
    for (let i = 0; i < count; i++) {
      // TODO: where does particleImage come from?
      /* eslint-disable no-undef */
      let b = new ImageParticle(
        particleImage,
        (canvas.width / (count + 1)) * (i + 1),
        canvas.height / 2
      );
      /* eslint-enable no-undef */

      particles.push(b);

      b.render(ctx);
    }
  }

  function setOriginPositions() {
    for (let i = 0; i < particles.length; i++) {
      let b = particles[i];
      b.originX = b.posX;
    }
  }

  function setStartPositions() {
    for (let i = 0; i < particles.length; i++) {
      let b = particles[i];
      b.startPositionX = b.posX;
    }
  }

  function changeAnimation(stage) {
    currentAnim.animationFrame = 0;

    if (stage == 1) {
      animStages.stage1.active = true;
      animStages.stage2.active = false;
      animStages.stage3.active = false;
      setStartPositions();
      currentAnim.endPosition = animStages.stage1.target;
    } else if (stage == 2) {
      animStages.stage1.active = false;
      animStages.stage2.active = true;
      animStages.stage3.active = false;
      setStartPositions();
      currentAnim.endPosition = animStages.stage2.target;
    } else {
      animStages.stage1.active = false;
      animStages.stage2.active = false;
      animStages.stage3.active = true;
      setStartPositions();
      currentAnim.endPosition = 0;
    }
  }

  function updateParticles() {
    for (let i = 0; i < particles.length; i++) {
      let b = particles[i];

      if (animStages.stage3.active && currentAnim.animationFrame < animDuration) {
        b.posX = moveParticles(
          b.startPositionX,
          b.originX,
          currentAnim.animationFrame,
          animDuration
        );
      } else if (currentAnim.animationFrame < animDuration) {
        b.posX = moveParticles(
          b.startPositionX,
          currentAnim.endPosition,
          currentAnim.animationFrame,
          animDuration
        );
      } else {
        currentAnim.animationFrame = animDuration - 1;
      }

      b.render(ctx);
    }

    currentAnim.animationFrame++;
  }

  function skipParticlesToStage(stage) {
    changeAnimation(stage);
    currentAnim.animationFrame = animDuration - 1;
  }

  function moveParticles(from, to, frame, duration) {
    let dist = to - from;
    let incrementMove;

    incrementMove = easeOutBounce(frame, from, dist, duration);

    return incrementMove;
  }

  function easeOutBounce(t, b, c, d) {
    if ((t /= d) < 1 / 2.75) {
      return c * (7.5625 * t * t) + b;
    } else if (t < 2 / 2.75) {
      return c * (7.5625 * (t -= 1.5 / 2.75) * t + 0.75) + b;
    } else if (t < 2.5 / 2.75) {
      return c * (7.5625 * (t -= 2.25 / 2.75) * t + 0.9375) + b;
    } else {
      return c * (7.5625 * (t -= 2.625 / 2.75) * t + 0.984375) + b;
    }
  }

  // https://github.com/sebleedelisle/CreativeJS2D/blob/master/1_Particles/js/ImageParticle.js
  function ImageParticle(img, posx, posy) {
    this.posX = posx;
    this.posY = posy;
    this.compositeOperation = 'source-over';
    this.img = img;
    let imgSize = img.height * imageScale;

    this.render = function(c) {
      c.save();
      c.translate(this.posX, this.posY);
      c.translate(imgSize * -0.5, imgSize * -0.5);
      c.globalCompositeOperation = this.compositeOperation;
      c.drawImage(img, 0, 0, imgSize, imgSize);
      c.restore();
    };
  }

  setup();
  skipParticlesToStage(1);

  $('#isa-saving-tool-tabs').on('change.zf.tabs', function(event, tab) {
    let tabTarget = tab.children('a').attr('href');
    if (tabTarget == '#panel1') {
      changeAnimation(1);
    } else if (tabTarget == '#panel2') {
      changeAnimation(3);
    } else {
      changeAnimation(2);
    }
  });
});
