import URL from 'core-js/web/url';
import _deburr from 'lodash/deburr';
import _mapValues from 'lodash/mapValues';

export class ReferFriendForm {
  constructor() {
    const { customerApi, supportApi } = this.getApiUrls(window.location.href);

    this.config = {
      customerApi,
      supportApi
    };

    this.$form = $();
    this.emailSubject = 'Direkt: Depotuebertrag';
    this.redirectionUrl = $('#redUrl').val();
  }

  /**
   * Connect the form to a specific element
   *
   * @param {JQuery} $form
   */
  // Modifying to avoid js errors on non contact-support pages. eg- UK sites.
  mount($form) {
    this.$form = $form;
    this.$form.on('submit', e => this.onFormSubmit(e));
    let parsley = null;
    if (typeof this.$form.parsley === 'function') {
      parsley = this.$form.parsley();
    }
    if (parsley) {
      parsley.on('form:validated', form => {
        $('.message-box--error').toggleClass('page-level-error', form.isValid());
      });
    }
  }

  /**
   * Choose the appropriate API endpoints based on the URL
   *
   * @param   {string} urlString URL of the current page
   * @returns Object containing `customerUrl` and `supportUrl` strings
   */
  getApiUrls(urlString) {
    const url = new URL(urlString);
    let apiBase;

    switch (url.hostname) {
      case 'localhost':
      case '127.0.0.1':
        apiBase = `${url.hostname}:3000`;
        break;
      case 'directnp.fidelity.de':
        apiBase = 'xapi.directnp.fidelity.de';
        break;
      case 'fidelitynp.fidelity.de':
        apiBase = 'xapi.directnp.fidelity.de';
        break;
      case 'direct.fidelity.de':
      default:
        apiBase = 'xapi.direct.fidelity.de';
        break;
    }

    return {
      customerApi: `${url.protocol}//${apiBase}/api/public/forms/refer-friend`,
      supportApi: `${url.protocol}//${apiBase}/api/public/forms/refer-friend-support`
    };
  }

  /**
   * Encode each of the values and convert to a JSON string
   *
   * @param  {object} data
   * @return {string}
   */
  encodeData(data) {
    return JSON.stringify(_mapValues(data, this.encodeSpecialChar));
  }

  /**
   * Escape special characters as the server cannot handle them
   *
   * @param  {string} value
   * @return {string}
   */
  encodeSpecialChar(value) {
    if (typeof value === 'string' && value.length > 0) {
      value = value
        .replace(/ä/g, 'ae')
        .replace(/ö/g, 'oe')
        .replace(/ü/g, 'ue')
        .replace(/Ä/g, 'Ae')
        .replace(/Ö/g, 'Oe')
        .replace(/Ü/g, 'Ue');

      value = _deburr(value);
    }
    return value;
  }

  onFormSubmit(e) {
    e.preventDefault();

    const anrede = $('input:radio[name=salutation_rec]:checked').val();
    const pre = anrede === 'Herr' ? 'Sehr geehrter' : 'Sehr geehrte';
    const name = jQuery('#nachname_neukunde').val();
    const vorname = jQuery('#vorname_neukunde').val();

    const begruessung = `${pre} ${anrede} ${vorname} ${name}`;

    const werber_anrede = jQuery('input:radio[name=salutation]:checked').val();
    const werber_gender = werber_anrede === 'Herr' ? 'er' : 'sie';

    const submitData = {
      $$MailTo: $('#email_neukunde').val(),
      $$Return: this.redirectionUrl,
      $$Subject: this.emailSubject,
      $line: '',
      anrede,
      begruessung,
      datenschutz_opt_in: $('#datenschutz_opt_in').is(':checked') ? 'ja' : 'nein',
      email: $('#email_neukunde').val(),
      EmailTo: $('#email_neukunde').val(),
      name,
      sc: 'DUE',
      SourceCode: 'DUE',
      Subject: this.emailSubject,
      TokenID: '',
      Tracking: '',
      vorname,
      werber_anrede,
      werber_depotnr: jQuery('#depotnr').val(),
      werber_email: jQuery('#email').val(),
      werber_gender,
      werber_name: jQuery('#nachname').val(),
      werber_vorname: jQuery('#vorname').val()
    };

    this.sendAll(submitData);
  }

  /**
   * Send data to both customer and support endpoints
   *
   * @param {object} data
   * @returns {JQueryPromise}
   */
  sendAll(data) {
    return $.when(this.sendToCustomer(data), this.sendToSupport(data)).then(() =>
      window.location.assign(this.redirectionUrl)
    );
  }

  /**
   * Generic AJAX POST wrapper function
   *
   * @param {object} data
   * @param {string} url
   * @returns jqXHR
   */
  sendData(data, url) {
    return $.ajax({
      contentType: 'application/json',
      data: this.encodeData(data),
      dataType: 'json',
      type: 'POST',
      url
    });
  }

  /**
   * POST the form data to the customer email endpoint
   *
   * @param {object} data
   * @return jqXHR
   */
  sendToCustomer(data) {
    return this.sendData(data, this.config.customerApi);
  }

  /**
   * POST the form data to the support email endpoint
   *
   * @param {object} data
   * @return jqXHR
   */
  sendToSupport(data) {
    return this.sendData(data, this.config.supportApi);
  }
}
