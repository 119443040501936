require( './yall.js' );

if ($('.fil-tabs:not(.links)').length == 0 && $('.fil-showhide').length == 0) {
  document.addEventListener('DOMContentLoaded', window.yall);
} else {
  $('img.lazy').each(function() {
    this.src = $(this).attr('data-src');
  });
  $('[data-bg-img]').each(function() {
    let src = $(this).attr('data-bg-img');
    $(this).css('background-image',`url(${  src  })`);
  });
}
