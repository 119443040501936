import URL from 'core-js/web/url';
import _deburr from 'lodash/deburr';
import _mapValues from 'lodash/mapValues';

class EmailForm {
  /**
   * Build a new email form based on the given jQuery element
   *
   * @param  {jQuery} $form
   */
  constructor($form) {
    this.$form = $form;
    this.$form.on('submit', e => this.onFormSubmit(e));

    const { action, actionSupport } = this.getApiUrls(window.location.href);

    this.config = {
      action,
      actionSupport,
      mail: 'fktwebprod@fil.com',
      redirectionUrl: $('#redUrl').val()
    };
  }

  /**
   * Choose the appropriate API endpoint based on the URL
   *
   * @param   {string} url URL of the current page
   * @returns {object} Object containing action and actionSupport URL strings
   */
  getApiUrls(urlString) {
    const url = new URL(urlString);
    let apiBase;

    switch (url.hostname) {
      case 'localhost':
      case '127.0.0.1':
        apiBase = `${url.hostname}:3000`;
        break;
      case 'qa.directnp.fidelity.de':
        apiBase = 'qa.xapi.directnp.fidelity.de';
        break;
      case 'directnp.fidelity.de':
        apiBase = 'xapi.directnp.fidelity.de';
        break;
      case 'fidelitynp.fidelity.de':
        apiBase = 'xapi.directnp.fidelity.de';
        break;
      case 'direct.fidelity.de':
      default:
        apiBase = 'xapi.direct.fidelity.de';
        break;
    }

    return {
      action: `${url.protocol}//${apiBase}/api/public/forms/contact`,
      actionSupport: `${url.protocol}//${apiBase}/api/public/forms/contact-support`
    };
  }

  onFormSubmit(e) {
    e.preventDefault();

    const formData = {
      mail: this.config.mail,
      EmailTo: this.$form.find('[name="kundeemail"]').val(),
      infopaket: this.$form.find('[name="infopaket"]').is(':checked') ? 'ja' : 'nein',
      anrede: this.$form.find('[name="anrede"]').val(),
      vorname: this.$form.find('[name="vorname"]').val(),
      nachname: this.$form.find('[name="nachname"]').val(),
      firma: this.$form.find('[name="firma"]').val(),
      strasse: this.$form.find('[name="strasse"]').val(),
      plz: this.$form.find('[name="plz"]').val(),
      ort: this.$form.find('[name="ort"]').val(),
      postinfo: this.$form.find('[name="postinfo"]').is(':checked') ? 'ja' : 'nein',
      kundeemail: this.$form.find('[name="kundeemail"]').val(),
      emailinfo: this.$form.find('[name="emailinfo"]').is(':checked') ? 'ja' : 'nein',
      telefon: this.$form.find('[name="telefon"]').val(),
      telefoninfo: this.$form.find('[name="telefoninfo"]').is(':checked') ? 'ja' : 'nein',
      datenschutz: this.$form.find('[name="datenschutz"]').is(':checked') ? 'ja' : 'nein',
      anmerkung: this.$form.find('[name="anmerkung"]').val(),
      thema: this.$form.find('[name="thema"]').val()
    };

    this.sendAll(formData);
  }

  /**
   * Encode each of the values and convert to a JSON string
   *
   * @param  {object} data
   * @return {string}
   */
  encodeData(data) {
    return JSON.stringify(_mapValues(data, this.encodeSpecialChar));
  }

  /**
   * Escape special characters as the server cannot handle them
   *
   * @param  {string} value
   * @return {string}
   */
  encodeSpecialChar(value) {
    if (typeof value === 'string' && value.length > 0) {
      value = value
        .replace(/ä/g, 'ae')
        .replace(/ö/g, 'oe')
        .replace(/ü/g, 'ue')
        .replace(/Ä/g, 'Ae')
        .replace(/Ö/g, 'Oe')
        .replace(/Ü/g, 'Ue');

      value = _deburr(value);
    }
    return value;
  }

  /**
   * Post the form data to the endpoint
   *
   * @param  {object} data Data to send
   * @return {jqXHR}
   */
  submitData(data, url) {
    return $.ajax({
      contentType: 'application/json',
      data: this.encodeData(data),
      dataType: 'json',
      type: 'POST',
      url: url,
      error() {
        console.error(arguments);
      }
    });
  }

  /**
   * Submit both API calls and redirect on success
   *
   * @param {object} data
   */
  sendAll(data) {
    $.when(this.sendToCustomer(data), this.sendToSupport(data)).then(() =>
      window.location.assign(this.config.redirectionUrl)
    );
  }

  /**
   * Call the API to send an email to the customer
   *
   * @param  {object} data
   * @return {jqXHR}
   */
  sendToCustomer(data) {
    return this.submitData(data, this.config.action);
  }

  /**
   * Call the API to send an email to the support team
   *
   * @param  {object} data
   * @return {jqXHR}
   */
  sendToSupport(data) {
    return this.submitData(data, this.config.actionSupport);
  }
}

module.exports = {
  EmailForm
};
