const VideoPaginator = require('./video_paginator');

$(document).ready(function() {
  /**
   * @description - video gallery functions
   */
  if ($('.fil-video-gallery .js-PlayVideo').modalVideo) {
    $('.fil-video-gallery .js-PlayVideo').modalVideo();
  }

  $('[data-paginator="video"]').each((i, el) => {
    const paginator = new VideoPaginator(el, {
      disableSEO: i > 0,
      disableURL: i > 0
    });
    paginator.rebuild();
  });

  let videoCount = $('.fil-video-gallery__item').length;
  if (videoCount == (10 || 25 || 50 || 100)) {
    $('.paginator__per-page').addClass('hide');
  }
});
