$(document).ready(function() {
  let CookieLib = {
    cookieExpiry: function() {
      let date = new Date();
      date.setTime(date.getTime() + 86400000 * 365); //1 year
      return date.toUTCString();
    },

    set: function(cname, cvalue) {
      let expires = `expires=${this.cookieExpiry()}`;
      // check domain here for german
      if (window.GLOBAL_FIL_COOKIE_DOMAIN != '') {
        document.cookie = `${cname}=${cvalue};${expires};domain=${
          window.GLOBAL_FIL_COOKIE_DOMAIN
        };path=/`;
      } else {
        document.cookie = `${cname}=${cvalue};${expires};path=/`;
      }
    },

    get: function(cname) {
      let name = `${cname}=`;
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return '';
    }
  };

  $.fn.initCookiePolicyPopup = function(_settings) {
    function onReject() {
      //stop the fil analytics
      CookieLib.set(settings['cookie-key'], 'false');
      $(this).hide();
    }

    function onAccept() {
      CookieLib.set(settings['cookie-key'], 'true');
      $(this).hide();
    }

    function checkVisibility(dom) {
      if (CookieLib.get(settings['cookie-key']) === '') {
        $(dom).removeAttr('hidden');
      }
    }

    const settings = {
      'cookie-key': window.GLOBAL_FIL_COOKIE_NAME
    };

    $.extend(settings, _settings);

    return $(this).each(function() {
      $(this)
        .find('.button--reject')
        .click($.proxy(onReject, this));
      $(this)
        .find('.button--accept')
        .click($.proxy(onAccept, this));
      checkVisibility(this);
    });
  };

  $('.fil-cookie-policy').initCookiePolicyPopup();
});
