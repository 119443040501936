(function() {
  //CMS-745 Undefined page:
  //this piece of polyfill fixes the issue that IE not support Event well,
  //which make the django cms page can't handle change-form submission and
  //trigger 'cms-content-refresh' event in IE browser
  if (typeof window.CustomEvent === 'function') {
    return false;
  } //If not IE

  function CustomEvent(event, params) {
    params = params || { bubbles: false, cancelable: false, detail: undefined };
    let evt = document.createEvent('CustomEvent');
    evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
    return evt;
  }

  CustomEvent.prototype = window.Event.prototype;
  window.Event = CustomEvent;
})();
