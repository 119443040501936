import { EmailForm } from './email-form';

jQuery(function() {
  new EmailForm($('#email_form'));
});

$(window).load(function() {
  let $emailForm = $('#email_form');
  if ($emailForm.length) {
    $emailForm.parsley().on('form:validated', function(formInstance) {
      let ok = formInstance.isValid();
      $('.message-box--error').toggleClass('page-level-error', ok);
    });
  }
});
