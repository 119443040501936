/**
 * @description - initialise Foundation
 */
$(document).ready(function() {
  if (typeof $(document).foundation === 'function') {
    $(document).foundation();
  } else {
    backupScripts();
  }

  /**
   * headless view mode.
   * sometimes we don't want to see the header, navigation menu and footer.
   * we can hide them by adding a "headless=true" in search parameter list.
   */
  if (location.search.search(/\bheadless=true\b/) >= 0) {
    $('#body-container').addClass('headless');

    $('body').on('click', 'a[href]', function(event) {
      const href = $(this)
        .attr('href')
        .trim();

      if (href && href[0] != '#' && !/^javascript:/.test(href)) {
        event.preventDefault();
        top.location.href = this.href;
      }
    });
  }
});

$(document).ready(function() {
  /**
   * @description - docs menu toggler
   */
  $('.js-docs-nav-toggle').click(function() {
    if ($(this).hasClass('is-active')) {
      $(this).removeClass('is-active');
      $('.docs-nav').removeClass('is-active');
    } else {
      $(this).addClass('is-active');
      $('.docs-nav').addClass('is-active');
    }
  });
});

let backupScripts = function() {
  backupFooter();
};

let backupFooter = function() {
  $('.fil-global-footer').addClass('backup-mode');
  $('.fil-global-footer .accordion-item').on('click', function() {
    $(this).toggleClass('is-active');
  });
};
