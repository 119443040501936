function changeDimensionOfArticleCardGrid() {
  const window_width = $(window).outerWidth();

  if (
    $('.fil-article-grid')
      .parent()
      .hasClass('left-br')
  ) {
    switch (true) {
      case window_width >= 1024:
        $('.left-br .articles')
          .removeClass(' grid-x grid-margin-x')
          .addClass('grid-y grid-margin-y');
        break;
      case window_width >= 640 && window_width < 1023:
        $('.left-br .articles')
          .removeClass(' grid-y grid-margin-y')
          .addClass('grid-x grid-margin-x');
        break;
      default:
        $('.left-br .articles')
          .removeClass(' grid-x grid-margin-x')
          .addClass('grid-y grid-margin-y');
        break;
    }
  }
}

$(window).on('load', function() {
  const height_of_image = $('.article-detail-component-wrapper').innerHeight();
  $('.article-bg-image-wrapper').css('height', height_of_image);
  $('.teaser-equalizer').css('height', 'auto');
  changeDimensionOfArticleCardGrid();
});

$(window).on('resize', function() {
  changeDimensionOfArticleCardGrid();
});
