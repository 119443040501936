/* global Foundation */

$(document).ready(function() {
  if (!$('#select50-app').length) {
    return;
  }

  let overlayCarouselOpts = {
    loop: true,
    margin: 10,
    items: 1,
    autoHeight: true,
    navContainer: '.select50-overlay_carousel .carousel-nav-tabs',
    onChanged: updateNav
  };

  function updateNav(event) {
    let page = event.page.index; // Position of the current page

    let thisNav = $('.select50-overlay .carousel-nav-tabs');
    let thisNavItems = thisNav.find('li');

    thisNavItems
      .removeClass('is-active')
      .eq(page)
      .addClass('is-active');
  }

  function initOverlayCarousel() {
    $('.select50-overlay_carousel').owlCarousel(overlayCarouselOpts);
    let select50Carousel = $('.select50-overlay_carousel');
    // register navigation element handlers
    let thisNav = $('.select50-overlay .carousel-nav-tabs');
    let thisNavItems = thisNav.find('li a');
    thisNavItems.click(function() {
      // get index
      let clickedElIndex = $(this)
        .parent()
        .index();
      select50Carousel.trigger('to.owl.carousel', [clickedElIndex, 250]);
    });
  }

  function initSelect50() {
    let delayBaseDuration = 0.2;
    $('#select50-results > section').each(function(index, el) {
      let thisDelay = delayBaseDuration + delayBaseDuration * index;
      $(el)
        .find('.section-inner')
        .css('transition-delay', `${thisDelay}s`);
    });

    $('#select50-results').addClass('results-loaded');
    initOverlayCarousel();
    // initTabs();
  }

  initSelect50();

  /**
   * On click, handle expansion and collapse of the fund details accordion.
   * This must also gracefully handle the automatic expansion of additional
   * funds, if they exist.
   */
  $('.fund-details_container .select50-showhide-fund-details').click(function() {
    const thisWrapper = $(this).closest('.fund-details_container');

    const accordion = thisWrapper.find('.fund-details_info-extended .accordion');
    const target = accordion.find('.accordion-content');

    const accordionAdditional = thisWrapper.find('.fund-details_additional');

    // Determine whether to expand or collapse accordion
    const direction = target.is(':visible') ? 'up' : 'down';
    accordion.foundation(direction, target);

    // If there are additional funds, expand the accordion and hide the
    // initial show / hide toggle.
    if (direction === 'down' && accordionAdditional.length) {
      const targetAdditional = accordionAdditional.find('.accordion-content');

      accordion.on('down.zf.accordion', event => {
        accordionAdditional.foundation('down', targetAdditional);
        accordion.off(event);
      });

      $(this).addClass('hidden');

      // Ensure toggle button displays 'Show less'
      const buttonAdditional = thisWrapper.find('.select50-showhide-subfunds');
      buttonAdditional.addClass('is-active');
    }
  });

  /**
   * On click, collapse the additional fund details and automatically collapse
   * the parent accordion as well.
   */
  $('.select50-showhide-subfunds').click(function() {
    let thisWrapper = $(this).closest('.fund-details_container');

    const accordion = thisWrapper.find('.accordion.fund-details_additional');
    const target = accordion.find('.accordion-content');

    const buttonParent = thisWrapper.find('.select50-showhide-fund-details');
    const accordionParent = thisWrapper.find('.fund-details_info-extended .accordion');
    const targetParent = accordionParent.find('.accordion-content');

    accordion.foundation('up', target);

    accordion.on('up.zf.accordion', () => {
      accordionParent.foundation('up', targetParent);
      buttonParent.removeClass('hidden').removeClass('is-active');
    });
  });

  // Broker accordion controls
  $('.broker-details_container .js-show-more').click(function() {
    let thisWrapper = $(this).closest('.broker-details_container');

    let accordion = thisWrapper.find('.broker-details_accordion');
    let $target = accordion.find('.accordion-content');

    accordion.foundation('toggle', $target);
  });

  if (Foundation.MediaQuery.is('small only')) {
    $('.broker-details_accordion .accordion-item').removeClass('is-active');
    $('.broker-details_accordion .accordion-content').css('display', 'none');
  }

  function accordionMediaQueryHandler(screenSize, accordionArr) {
    let mobileLayoutQuery = 'small';
    if (screenSize !== mobileLayoutQuery) {
      accordionArr.each(function(index, $el) {
        let acc = $($el).eq(index);
        let accItem = $($el).find('.accordion-item');
        let panel = accItem.find('.accordion-content');
        acc.foundation('down', panel, false);
        acc.attr('disabled', 'disabled');
      });
    } else {
      accordionArr.each(function(index, $el) {
        let acc = $($el).eq(index);
        let accItem = $($el).find('.accordion-item');
        let panel = accItem.find('.accordion-content');
        acc.removeAttr('disabled');
        acc.foundation('up', panel);
        accItem.removeClass('is-active');
      });
    }
  }

  $(window).on('changed.zf.mediaquery', function(event, newSize) {
    accordionMediaQueryHandler(newSize, $('.broker-details_accordion'), false);
  });

  /**
   * Fund Category Filtering
   *
   * Each tab has a data-filter attribute containing the slug of a fund
   * category. This slug matches the data-category attribute on the
   * appropriate containing <div> element.
   *
   * When the user clicks a tab, it must filter the categories by showing only
   * the appropriate category <div>.
   *
   * A special filter, __all__, removes the filter and display all categories.
   */
  $('.select50-category-tabs .tabs-title').click(function() {
    let filter = $(this).data('filter');

    let $categories = $(this)
      .parent()
      .parent()
      .find('.select50-category-wrapper');

    $categories.each((i, el) => {
      let visible = false;

      if (filter === '__all__') {
        visible = true;
      } else if (filter === $(el).data('category')) {
        visible = true;
      }

      $(el).toggle(visible);
    });
  });

  /**
   * When closing the overlay, the page scroll position will jump to the
   * bottom. This happens when there are multiple anchors available to trigger
   * the overlay. On close, Foundation uses the *last* anchor determine the
   * scroll position.
   *
   * This code works around the issue by triggering the overlay manually
   * through JavaScript events.
   *
   * The issue should be resolved in Foundation 6.5
   *
   * https://stackoverflow.com/q/45025784
   * https://github.com/zurb/foundation-sites/issues/10604
   */
  $('.trigger-select50-modal').click(() => {
    $('#select50info').foundation('open');
  });

  /**
   * Remove the inline height style from .tabs-content
   *
   * On initial load, the FIL tabs script automatically sets the height of the
   * tabs content block. Unfortunately, the tabs in Select 50 are hidden inside
   * a closed Foundation modal and report 0 height. Setting the inline style to
   * use 0px height just causes the tab content to be permanently hidden, even
   * when the respective tab is selected. Removing this style fixes the issue.
   */
  $('.select50-overlay').on('open.zf.reveal', evt => {
    $(evt.target)
      .find('.tabs-content')
      .css('height', '');
  });

  /**
   * Recalculate modal height when tabs change
   *
   * Disparity between the length of broker descriptions can cause text to
   * overflow the modal. Hook into the tab change event and force a height
   * recalculation by refreshing the Owl Carousel that wraps the tabs.
   */
  $('.select50-overlay .tabs-portrait').on('change.zf.tabs', () =>
    $('.select50-overlay .owl-carousel').trigger('refresh.owl.carousel')
  );
});
