/* global Foundation */

$(document).ready(function() {
  // Declare Carousel jquery object
  let owl = $('.fil-carousel-container');
  if (!owl.length) {
    return;
  }

  // Carousel initialization
  owl.owlCarousel({
    items: 1,
    margin: 30,
    nav: true,
    navSpeed: 500,
    startPosition: 0,
    navText: [
      '<span class="fil-icon fil-icon-arrow-l-light"></span>',
      '<span class="fil-icon fil-icon-arrow-r-light"></span>'
    ],
    rewind: false,
    loop: false,
    checkVisible: false
  });

  let $navButtons = owl.find('.owl-nav > button');
  if ($navButtons.length > 0 && window.prevNavLabel && window.nextNavLabel) {
    $navButtons.eq(0).attr('aria-label', window.prevNavLabel);
    $navButtons.eq(1).attr('aria-label', window.nextNavLabel);
  }

  // add animate.css class(es) to the elements to be animated
  function setAnimation(_elem, _InOut) {
    // Store all animationend event name in a string.
    // cf animate.css documentation
    let animationEndEvent =
      'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';

    _elem.each(function() {
      let $elem = $(this);
      let $animationType = `animated ${$elem.data(`animation-${_InOut}`)}`;

      $elem.addClass($animationType).one(animationEndEvent, function() {
        $elem.removeClass($animationType); // remove animate.css Class at the end of the animations
      });
    });
  }

  // Fired before current slide change
  owl.on('change.owl.carousel', function(event) {
    let $currentItem = $('.owl-item', owl).eq(event.item.index);
    let $elemsToanim = $currentItem.find('[data-animation-out]');
    setAnimation($elemsToanim, 'out');
  });

  // Fired after current slide has been changed
  let round = 0;
  owl.on('changed.owl.carousel', function(event) {
    let $currentItem = $('.owl-item', owl).eq(event.item.index);
    let $elemsToanim = $currentItem.find('[data-animation-in]');
    setAnimation($elemsToanim, 'in');
  });

  owl.on('translated.owl.carousel', function(event) {
    if (event.item.index == event.page.count - 1) {
      if (round < 1) {
        round++;
      } else {
        owl.trigger('stop.owl.autoplay');
        let owlData = owl.data('owl.carousel');
        owlData.settings.autoplay = false; //don't know if both are necessary
        owlData.options.autoplay = false;
        owl.trigger('refresh.owl.carousel');
      }
    }
  });

  if ($('.fil-comparison-list-container').length) {
    let owlComparison = $('.fil-comparison-list-container');

    // Add the class for the carousel to work
    // eslint-disable-next-line no-inner-declarations
    function owlClass() {
      $('.fil-comparison-list-container').addClass('owl-carousel owl-theme');
    }

    // Initialize Carousel for Comparison List
    // eslint-disable-next-line no-inner-declarations
    function owlInitialize() {
      owlComparison.owlCarousel({
        loop: true,
        margin: 0,
        responsive: {
          0: {
            items: 1
          }
        }
      });
    }

    // Check the heights for mobile to make the lists same size
    // eslint-disable-next-line no-inner-declarations
    function applyHeights() {
      $('.fil-comparison-list-container').foundation('getHeights', function(e) {
        $('.fil-comparison-list-container').foundation('applyHeight', e);
      });
    }

    // If it's not mobile remove the carousel
    $(window).on('changed.zf.mediaquery', function(event, newSize, oldSize) {
      if (newSize === 'medium' && oldSize === 'small') {
        $('.fil-comparison-list-container').owlCarousel('destroy');
        $('.fil-comparison-list-container').removeClass('owl-carousel owl-theme');
      }
    });

    // Check window rezise and trigger the carousel
    $(window).on('changed.zf.mediaquery', function(event, newSize, oldSize) {
      if (newSize === 'small' && oldSize === 'medium') {
        owlClass();
        owlInitialize();
        applyHeights();
      }
    });

    // Trigger the carousel in mobile devices
    if (Foundation.MediaQuery.is('small only')) {
      owlClass();
      owlInitialize();
      applyHeights();
    }
  }

  /*
    Puts the article teasers inside a carousel on mobile.
  */
  if (Foundation.MediaQuery.is('small only')) {
    $('.fil-article-grid .articles').addClass('owl-carousel owl-theme');
    $('.owl-carousel').owlCarousel({
      loop: true,
      margin: 0,
      responsive: {
        0: {
          items: 1
        },
        600: {
          items: 3
        },
        1000: {
          items: 5
        }
      }
    });
  }
});
