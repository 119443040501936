const handleTooltipClick = $infoIcon => () =>
  $infoIcon.addClass('fil-outlook-table__info-icon--clicked');

const handleTooltipClose = $infoIcon => () => {
  $infoIcon.removeClass('fil-outlook-table__info-icon--clicked');
  return false; // stop propagation which would cause this tooltip to be shown again!
};

const initialiseHandlers = () => {
  $('.fil-outlook-table__info-icon').each((i, node) => {
    let $node = $(node);
    $node.on('click', handleTooltipClick($node));
  });

  $('.fil-outlook-table__close-icon, .fil-outlook-table__close-button').each((i, node) => {
    let $node = $(node);
    $node.on('click', handleTooltipClose($node.closest('.fil-outlook-table__info-icon')));
  });
};

$(document).ready(initialiseHandlers);

module.exports = {
  initialiseHandlers
};
